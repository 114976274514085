import { App } from './app'

const headerButtons = document.createElement('div')
headerButtons.innerHTML = `
  <a class="btn btn-telegram" href="https://t.me/+xHYyZwKtLtE5NTFi" role="button" target="_blank"><span class="fw-semibold">Telegram</span></a>
  <button id="sort-by" class="btn btn-primary d-none">Sort By<i class="bi bi-chevron-right mx-1" aria-label="category"></i><span class="fw-semibold" id="sort-by-text"></span></button>
  <button id="display-format" class="btn btn-primary d-none">Display<i class="bi bi-chevron-right mx-1" aria-label="category"></i><span class="fw-semibold" id="display-format-text"></span></button>
  <button id="theme" class="btn btn-primary">Theme<i class="bi bi-chevron-right mx-1" aria-label="category"></i><span class="fw-semibold" id="theme-text"></span></button>
  <button id="settings-toggle" class="btn btn-primary d-none">Settings</button>
`
document.getElementById(window.innerWidth > 576 ? 'header-buttons' : 'header-buttons-sm').innerHTML = headerButtons.innerHTML

const app = new App()

document.addEventListener('DOMContentLoaded', () => {
  app.init()

  window.addEventListener('resize', function () {
    app.percentageBar.redraw()

    // Delegate to GraphDisplayManager which can check if the resize is necessary
    app.graphDisplayManager.requestResize()
  }, false)
}, false)
