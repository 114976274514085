const OPTIONS = [
  {
    getName: () => 'System'
  },
  {
    getName: () => 'Light'
  },
  {
    getName: () => 'Dark'
  }
]

const THEME_OPTION_INDEX_DEFAULT = 0
const THEME_OPTION_INDEX_STORAGE_KEY = 'livemc_theme_option_index'

export class ThemeManager {
  constructor (app) {
    this._app = app
    this._buttonElement = document.getElementById('theme')
    this._textElement = document.getElementById('theme-text')
    this._themeOptionIndex = THEME_OPTION_INDEX_DEFAULT
  }

  loadLocalStorage () {
    if (typeof localStorage !== 'undefined') {
      const themeOptionIndex = localStorage.getItem(THEME_OPTION_INDEX_STORAGE_KEY)
      if (themeOptionIndex) {
        this._themeOptionIndex = parseInt(themeOptionIndex)
      }
    }
  }

  updateLocalStorage () {
    if (typeof localStorage !== 'undefined') {
      if (this._themeOptionIndex !== THEME_OPTION_INDEX_DEFAULT) {
        localStorage.setItem(THEME_OPTION_INDEX_STORAGE_KEY, this._themeOptionIndex)
      } else {
        localStorage.removeItem(THEME_OPTION_INDEX_STORAGE_KEY)
      }
    }
  }

  init () {
    this.loadLocalStorage()

    this.updateOption()

    this._buttonElement.addEventListener('click', this.handleThemeButtonClick)
  }

    handleThemeButtonClick = () => {
      this._themeOptionIndex = (this._themeOptionIndex + 1) % OPTIONS.length

      this.updateOption()

      this.updateLocalStorage()
    }

    updateOption = () => {
      const option = OPTIONS[this._themeOptionIndex]

      this._textElement.innerText = option.getName()

      this.applyTheme()
    }

    applyTheme () {
      switch (this._themeOptionIndex) {
        case 0:
          document.documentElement.setAttribute('data-bs-theme', (window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light'))
          document.querySelector('meta[name="theme-color"]').setAttribute('content', (window.matchMedia('(prefers-color-scheme: dark)').matches ? '#171717' : '#FFF'))
          break
        case 1:
          document.documentElement.setAttribute('data-bs-theme', 'light')
          document.querySelector('meta[name="theme-color"]').setAttribute('content', '#FFF')
          break
        case 2:
          document.documentElement.setAttribute('data-bs-theme', 'dark')
          document.querySelector('meta[name="theme-color"]').setAttribute('content', '#171717')
          break
      }
    }
}
